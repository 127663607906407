
.App {
    text-align: left;
    font-family: Arial, serif;
    /*height: 100vh;*/
    font-size: 14px;

    position: relative;
    min-height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.home-page-main {
    margin-right: 2%;
    /*height:100vh;*/
}

.empty-space {
    /*height: 100vh;*/
    background-color: #EFF2F6;
}

.banner {
    text-align: left;
    padding-left: 1%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 160%;
    background-color: #003FA3;
}


/*.footer{*/
/*    !*margin-top: 1rem;*!*/
/*    background-color: #444;*/
/*    !*position: fixed;*!*/
/*    bottom: 0;*/
/*    width: 100%;*/
/*    color: white;*/
/*    font-size: 80%;*/
/*    justify-content: center;*/
/*    display:flex;*/
/*}*/


.content-wrap {
    padding-bottom: 2.5rem;    /* Footer height */
}
.footer-style {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 2.5rem;            /* Footer height */

    justify-content: center;
    background-color: #444;
    /*position: fixed;*/
    color: white;
    font-size: 80%;
    display:flex;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(46,118,187)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
    border-color: #003FA3;
    border-width: 1px;
    color: #003FA3;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.nav-link {
    margin-inline: 35px;
    font-size: 100%;
    text-underline-offset: 8px;
    color: black;
}

.list-with-link {
    font-size: 90%;
    margin-left: 75px;
}

.individual-link{
    padding: 2px;
}

.prelim-nonlist-with-link{
    font-size: 90%;
    margin-left: 40px;
}

.prelim-nonlist-with-link a:hover {
    text-decoration: none;

}

.nonlist-with-link{
    font-size: 100%;
    margin-left:-20px;
}

.nonlist-with-link a:hover {
    text-decoration: none;

}


.link-padding{
    padding-bottom: 2px;
    border-bottom-style: solid;
    border-bottom-width:1px;
    margin-left: -10px;
}

.list-with-link-website{
    font-size: 100%;
    margin-left: 55px;
    text-decoration: underline;
}

.footer-insufficient-data{
    font-weight: normal;
    font-size:110%;
}

.home-page-publish-dates{
    font-weight: normal;
}

.list-with-link-last-survey{
    font-size: 100%;
    margin-left: 55px;
    padding-bottom: 50px;
    text-decoration: underline;
}

.text-bold {
    font-size: 100%;
    margin-top: 20px;
    margin-left: 40px;
    font-weight: bold;
}

.text-section{
    font-size: 110%;
}

.text-section-title{
    font-size: 90%;
}

hr{
    width:110%;
}
.new-line{
    display: block;
    margin-left: 10px;
}

.new-line-historical{
    display: block;
    margin-left: 60px;
    font-size:90%;
}
.text-bold-only {
    font-weight: bold;
    font-size:90%;

}
.next-survey-section{
    margin-top:3%;
}

.text-regular {
    font-size: 100%;
    margin-left: 40px;
}

.text-regular-only {
    font-size: 90%;
}

.text-regular-indented {
    font-size: 90%;
    margin-left: 70px;
}

.text-regular-indented-home-page {
    font-size: 100%;
    margin-left: 40px;
}

h4 {
    font-size: 175%;
    margin-top: 20px;
    color: #003FA3;
    margin-left: 30px;
    font-weight: bold;
}

.disactivated-prelim-page-note{
    font-size: 140%;
    margin-top: 20px;
    margin-left: 30px;
}

a {
    color: #003FA3;
}

.country-div-container {
}

.pane {
    /*height: 100vh;*/
    border-right: 1px solid #EFF2F6;
    padding-left: 0.5%;
    padding-right: 0.5%;
    background-color: #EFF2F6;
}

.table-area {
    display: none;
    font-size: 90%;
    height: 100vh;
    margin: 0 auto;
}

.container-margin{
    margin-left:0;
}

.practice-container-margin{
    margin-left:40%;
}
.chart-container{
    margin-top: 20px;
    font-size:110%;
}

.table {
    border: 1px solid lightgrey;
}

.tableFixHead {
    margin-top: 5px;
    overflow: auto;
    height: 75%;
}
.tableFixedHead-for-print{
    margin-top: 10px;
    overflow: auto;
}

.tableFixHead thead th {
    position: sticky;
    top: 10;
    z-index: 1;
    border:none;
    background-color: #003FA3 !important;
    color:white;
}

.survey-participants{
    color: black;
    font-size:90%;
    margin-bottom:20px;
    /*height: 90vh;*/
    overflow-x: hidden;
    overflow-y: auto;
}


.survey-participants-title{
    font-size:135%;
    margin-top: 25px;
    margin-bottom: 25px;
    margin-left:-2%;
}

table {
    /*border-collapse: collapse;*/
    width: 100%;
}

/*changed from collapse to separate due to the bug in firefox*/
th, td {
    padding: 8px 16px;
}

th {
    background: #eee;
}

tfoot {
    background: #eee;
    font-weight: bold;
    font-size: 88%;
}

.report-seperator {
    font-size: 120%;
    color: #003FA3;
    margin-top: 3%;
    margin-bottom: 0;
    font-weight: bold;
}

.logo-print-view{
    display: flex;
    justify-content: center;
}

.freeze-and-year-filters {
    color: dimgray;
    position: relative;
    padding-left: 1%;
    margin-top: 2%;
    margin-bottom: 2%;
    /*border: 1.5px solid darkgrey;*/
    font-size: 88%;
}

.react-select > div {
    margin: -1px;
    border-color: darkgrey;
}

.reset-button {
    font-size: 88%;
}

.filter-labels {
    margin-bottom: 1%;
    font-size:100%
}

.datacuts-filters {
    display: none;
    margin-bottom: 2%;
}

.drop-down-container {
    width: 90%;
}

.clear-button {
    /*margin-top: 1vh;*/
    margin-top: 3%;
    display: none;

}

.table-title {
    margin-top: 1%;
    margin-bottom: 1%;
    color: #003FA3;
    font-weight: bold;
    text-align: center;
    font-size: 170%;
}

.table-first-row {
    background-color: grey;
    font-weight: bolder;
    white-space:pre;
    height: 65px;
}

.table-first-row-practice {
    background-color: #003FA3;
    font-weight: bold;
    text-align: center;
    color: white;
    white-space:pre;
    height: 65px;    
}

.table-first-column-practice {
    background-color: #003FA3;
    font-weight: bold;
    color: white;
    white-space:pre;
    height: 65px;    
}

.table-row-practice{
    font-weight: normal;
    text-align: center;
    font-size:95%;
}

.vertical-align{
    text-align:center;
    top: 50%;
    left: 50%;
    margin: 0;
    position: absolute;
    transform: translate(-50%, -50%);
}

.bottom-align{
    text-align:center;
    vertical-align: bottom;
}

.td-all-data-name {
    background-color: lightgrey;
    font-weight: bold;
    text-align: left;
}
.td-all-data-numbers {
    background-color: lightgrey;
    font-weight: bold;
    text-align: center;
}


.td-sub-group-name {
    /*background-color: #DDEBF7;*/
    text-align: left;
    font-weight: normal;
}

.td-sub-group-numbers {
    /*background-color: #DDEBF7;*/
    text-align: center;
    font-weight: normal;
}

.td-mini-group-name {
    background-color: #FAFAFA;
    text-align: left;
    font-weight: normal;
}

.td-mini-group-numbers {
    background-color: #FAFAFA;
    text-align: center;
    font-weight: normal;
}


.td-group-name {
    background-color: #D3D3D3;
    text-align: left;
    font-weight: normal;
}

.td-group-numbers {
    background-color: #D3D3D3;
    text-align: center;
    font-weight: normal;
}


.td-name {
    text-align: left;
    font-weight: normal;
    background-color: #ffffff;
}

.td-grey {
    font-weight: normal;
    text-align: center;
    background-color: #f5f5f5;
}


.td-normal {
    text-align: center;
    font-weight: normal;
    background-color: #ffffff;
}


#datacuts-selected {
    display: none;
    text-align: center;
    font-size: 94%;
    margin-bottom: 1vh;
}

.datacut-type {
    font-weight: bold;
}

.survey-file-images{
    border-style:solid; border-width:0; height:15px; margin-left:5px; margin-right:5px; width:13px
}

.flex-container {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1%;
}

.flex-item {
    /*padding:0 1px;*/
    /*border: 1px solid #ccc;*/
    margin: 3px
}

.flex-item-salary-freeze-annotation-text {
    /*padding:0 1px;*/
    /*border: 1px solid #ccc;*/
    /*margin: 3px*/
    display: table;
    margin:0 auto;
}

@keyframes example {
    from {
        background-color: white;
        color: black
    }
    to {
        background-color: #003FA3;
        color: black
    }
}


.chart-area {
    height: 90vh;
    display: none;
    font-size: 88%;
}

.Aligner {
    display: flex;
    align-items: center;
    justify-content: center;
}


.chart-div {
    border: 1px solid #003FA3;
    margin: 0 auto;
    align-self: flex-start;
}

.x-axis-label {
    font-size: 94%;
    margin-left: 50%;
    align-self: flex-start;
    margin-bottom: 2%;
}

.data-availability {
    text-align: left;
    font-size: 110%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    color: red;
}

.practice-data-annotation{
    text-align: center;
    font-size: 110%;
    margin-top: 10px;
    margin-bottom: 10px;
    color: red;
}
.practice-data-question{
    text-align: left;
    font-weight: bold;
    font-size: 120%;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #003FA3;
}
.left.axis {
    font-size: 94%;
}

.bottom.axis {
    font-size: 94%;
}


th {
    position: sticky;
}


#loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 120px;
    height: 120px;
    margin: -76px 0 0 -76px;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #003FA3;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



.percentile-labels{
    color: #003FA3;
    margin-left: 0.5rem;

}
.pop-up-menu{
    margin: auto;
    background: rgb(255, 255, 255);
    width: max-content;
    padding: 20px;
    border: 2px solid #003FA3;
    /*font-weight: bold;*/
    font-size:88%;
}



.pop-up-div{
    text-align: right;
}

.column-selection-button{
    font-size: 100%;
    background:white;
    border:none;
    text-decoration: underline;
}


.column-selection-button:hover{
    background-color:white;
    text-decoration: underline;
    border:none;
}

.column-selection-button:active{
    background-color:white;
    text-decoration: underline;
    border:none;
}


.footer-links {
    color: white;

}

.footer-links:hover {
    color: orange;

}

.practice-response-number-text{
    font-size: 100%;
}

.practice-survey-source{
    font-weight: normal;
    padding-bottom: 10vh;
}

.printVisible {
    display: none;
}

.font-italic {
    font-style: italic;
}

@media print {


    .printVisible {
        display: block !important;
    }

    .printHidden {
        display: none !important;
    }



    .table td {
        background-color: transparent !important;
    }
    .table th {
        background-color: #003FA3 !important;
    }


    .table th.td-all-data-name {
        background-color: lightgrey !important;

    }

    .table th.td-all-data-numbers {
        background-color: lightgrey !important;
    }

    .table th.td-mini-group-name {
        background-color: #FAFAFA !important;
    }

    .table th.td-mini-group-numbers {
        background-color: #FAFAFA !important;
    }

    .table th.td-group-name {
        background-color: #D3D3D3 !important;
    }

    .table th.td-group-numbers {
        background-color: #D3D3D3 !important;
    }

    .table th.td-name {
        background-color: #ffffff !important;
    }

    .table th.td-grey {
        background-color: #f5f5f5 !important;
    }

    .table th.td-normal {
        background-color: #ffffff !important;
    }

    .table th.td-sub-group-name {
        background-color: #f5f5f5 !important;
    }

    .table th.td-sub-group-numbers {
        background-color:#f5f5f5 !important;
    }

}

.next-survey-div{
    margin-bottom: 15vh;
}

.prelim-site-space-holder{
    margin-bottom: 30vh;
}

.div-404{
    margin-bottom: 100vh;
}

.definition-name{
    font-weight: bold;
}

.definition-rows{
    margin-bottom: 1rem;
}
.definition-title{
    margin-bottom:2rem;
    text-align: center;
}

.export-file-images{
    border-style:solid; 
    border-width:0; 
    width:16px; 
    height:16px; 
    margin-right: 1%;
    float: right;
}